<template>
    <div class="row mt-2">
        <div class="col-12">
            <!-- First row wrapper -->
            <div class="d-flex justify-content-between pb-2">
                <!-- Left side -->
                <div class="btn-toolbar">
                    <!-- Primary actions -->
                    <template v-if="bulk === false">
                        <button v-for="(action, i) in primaryActions"
                                :id="action.id"
                                :key="'primary-action-' + i"
                                :aria-expanded="action.type === 'dropdown' ? 'false' : 'true'"
                                :class="[action.color ? 'btn-' + action.color : 'btn-primary',
                                         action.type === 'dropdown' ? 'dropdown-toggle' : '']"
                                :data-bs-target="action.type === 'modal' ? action.target : ''"
                                :data-bs-toggle="action.type"
                                :disabled="action.disabled"
                                class="btn me-1"
                                type="button"
                                @click="action.type === 'function' ? $emit(action.target) : ''">
                            <font-awesome-icon v-if="action.icon" :icon="action.icon" class="me-1"/>
                            {{ action.text }}
                        </button>
                    </template>

                    <!-- Bulk actions -->
                    <template v-if="bulk === true">
                        <div class="d-inline-block me-1 dropdown">
                            <button id="actions-bulk-toggle"
                                    :disabled="bulkActions === null || bulkActions.disabled || bulkActions.length <= 0"
                                    aria-expanded="false"
                                    class="btn btn-light dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    type="button">
                                {{ this.$tc('action', 2) }}
                            </button>
                            <ul aria-labelledby="actions-bulk-toggle" class="dropdown-menu">
                                <li>
                                    <button v-for="(item, i) in bulkActions"
                                            :key="'bulk-action-' + i"
                                            :data-bs-target="item.type === 'modal' ? item.target : ''"
                                            :data-bs-toggle="item.type === 'modal' ? 'modal' : ''"
                                            :disabled="item.disabled"
                                            class="dropdown-item"
                                            type="button"
                                            @click="item.type === 'function' ? $emit(item.target) : ''">
                                        {{ item.text }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </template>

                    <!-- Secondary actions -->
                    <div v-if="secondaryActions !== null && secondaryActions.length > 0"
                         class="dropdown d-inline-block">
                        <button id="secondary-actions"
                                aria-expanded="false"
                                class="btn btn-link link-dark"
                                data-bs-toggle="dropdown"
                                type="button">
                            <font-awesome-icon icon="ellipsis-h"/>
                        </button>
                        <ul aria-labelledby="secondary-actions" class="dropdown-menu">
                            <li v-for="(action, j) in secondaryActions" :key="'secondary-action-' + j">
                                <button v-if="!action.click"
                                        :data-bs-target="action.target ? action.target : ''"
                                        :data-bs-toggle="action.type === 'modal' ? 'modal' : ''"
                                        :disabled="action.disabled"
                                        class="dropdown-item"
                                        type="button">{{ action.text }}
                                </button>
                                <button v-else
                                        :disabled="action.disabled"
                                        class="dropdown-item"
                                        type="button"
                                        @click="action.click">{{ action.text }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Right side -->
                <div class="d-flex" style="gap: 5px">
                    <button class="btn btn-light" @click="$emit('refresh')">
                        <font-awesome-icon icon="sync-alt"/>
                    </button>
                    <div v-if="actionFilters.length > 0" class="dropdown">
                        <button id="actions-filter"
                                aria-expanded="false"
                                aria-haspopup="true"
                                class="btn btn-light dropdown-toggle"
                                type="button"
                                @click="filterOpen = true;">
                            {{ $tc('filter', 1) }}
                            <span v-if="activeFiltersCount > 0"
                                  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                                  style="z-index: 1">
                                {{ activeFiltersCount }}
                                <span class="visually-hidden">{{ $tc('filter', 2) }}</span>
                            </span>
                        </button>
                        <div id="filter-container"
                             :class="{ show: filterOpen }"
                             class="dropdown-menu dropdown-menu-right p-3 pe-4"
                             style="width: 600px; right: 0; margin-top: .5em;">

                            <div v-for="(selectedFilter, i) in selectedFilters"
                                 :key="'action-filter-' + i"
                                 class="d-flex justify-content-between mb-2 flex-wrap row">
                                <div class="col-3 pe-0">
                                    <select id="filter-name"
                                            v-model="selectedFilter.key"
                                            class="form-select form-select-sm"
                                            name="filterName"
                                            @change="onChangeFilter($event.target.value, i)">
                                        <option :value="selectedFilter.key">
                                            {{ selectedFilter.title }}
                                        </option>
                                        <option v-for="(availableFilter, j) in availableFilters"
                                                :key="'action-sub-filter-' + i + '-' + j"
                                                :value="availableFilter.key">
                                            {{ availableFilter.title }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-4 pe-0">
                                    <select id="filterOperators"
                                            v-model="selectedFilter.operator"
                                            class="form-select form-select-sm"
                                            name="filterOperators"
                                            @change="setFilterOptions(i)">
                                        <option value="eq">{{ $t('filter-operators.equal-to') }}</option>
                                        <option value="ne">{{ $t('filter-operators.not-equal-to') }}</option>
                                        <!--
                                        <option value="gt">{{ $t('filter-operators.greater-than') }}</option>
                                        <option value="gte">{{ $t('filter-operators.greater-than-or-equal-to') }}</option>
                                        <option value="lt">{{ $t('filter-operators.less-than') }}</option>
                                        <option value="lte">{{ $t('filter-operators.less-than-or-equal-to') }}</option>
                                        -->
                                        <option value="like">{{ $t('filter-operators.like') }}</option>
                                        <option value="in">{{ $t('filter-operators.one-of') }}</option>
                                        <option value="notIn">{{ $t('filter-operators.none-of') }}</option>
                                    </select>
                                </div>
                                <div class="col-5">
                                    <select v-if="selectedFilter.operator !== 'in' && selectedFilter.operator !== 'notIn'"
                                            id="filter-value"
                                            v-model="selectedFilter.value"
                                            class="form-select form-select-sm"
                                            name="filterValue">
                                        <option v-for="(filter, k) in getFilterOptions(selectedFilter.key)"
                                                :key="'action-filter-value-' + k"
                                                :value="filter.value">
                                            {{ filter.name }}
                                        </option>
                                    </select>
                                    <template v-else>
                                        <multiselect v-model="selectedFilter.value"
                                                     :close-on-select="false"
                                                     :multiple="true" :options="getFilterOptions(selectedFilter.key)"
                                                     :placeholder="$t('actions.please-select')"
                                                     :taggable="true"
                                                     label="name"
                                                     track-by="value"/>
                                    </template>
                                </div>

                            </div>
                            <hr>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-sm btn-light" @click="addFilter">
                                    <font-awesome-icon icon="plus"/>
                                    {{ $t('actions.add-filter') }}
                                </button>
                                <div>
                                    <button class="btn btn-sm btn-danger"
                                            @click="selectedFilters = []; applyFilters(); filtersApplied = false;">
                                        {{ $t('actions.clear-filters') }}
                                    </button>
                                    <button class="btn btn-sm btn-primary ms-2"
                                            @click="applyFilters(); filtersApplied = true;">
                                        {{ $t('actions.apply-filters') }}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <nav class="navbar navbar-light p-0">
                        <form class="d-flex" @submit.prevent="generalSearch">
                            <div class="input-group">
                                <input v-model="searchTerm"
                                       :aria-describedby="$t('actions.search')"
                                       :aria-label="$t('actions.search')"
                                       :placeholder="$t('actions.search')"
                                       class="form-control"
                                       type="text">
                                <button v-if="extendedSearch"
                                        class="btn btn-primary btn-primary-sm m-0"
                                        type="button"
                                        @click="searchExpanded = !searchExpanded">
                                    <font-awesome-icon :icon="searchExpanded ? 'chevron-up' : 'chevron-down'"/>
                                </button>
                            </div>
                        </form>
                    </nav>
                </div>
            </div>

            <!-- Bottom bar -->
            <div v-if="searchExpanded" id="extendedSearch" class="d-flex flex-wrap p-3">
                <form class="d-flex flex-wrap align-items-end" @submit.prevent="detailSearch">
                    <div v-for="(search, i) in extendedSearch"
                         :key="'extended-search-' + i"
                         class="d-flex flex-column me-2"
                         style="width: 220px;">
                        <label class="sr-only" for="actions-search">{{ search.name }}</label>
                        <input id="actions-search"
                               v-model="extendedSearchValues[i][search.technicalName]"
                               :placeholder="search.name"
                               class="form-control form-control-sm"
                               type="text">
                    </div>
                    <button class="btn btn-sm btn-primary">{{ $t('actions.search') }}</button>
                </form>
            </div>
            <div class="d-flex justify-content-between my-2">
                <div>
                    <small>{{ $tc('items-total', total, {items: total}) }}</small>
                </div>
                <component-pagination v-if="itemsPerPage < total && itemsPerPage !== null"
                                      :limit="itemsPerPage"
                                      :page="page"
                                      :total="total"
                                      @paginate="paginate"/>
                <div>
                    <small>
                        {{ $t('items-per-page') }}:

                        <button v-for="(perPage, i) in itemsPerPageOptions"
                                :key="'items-per-page-option-' + i"
                                :class="{ 'text-primary': itemsPerPage !== perPage, 'ms-1': i > 0, 'text-decoration-underline': itemsPerPage === perPage }"
                                :disabled="itemsPerPage === perPage"
                                class="btn btn-transparent p-0 btn-sm"
                                @click="$emit('changeItemsPerPage', perPage)">
                            {{ perPage }}
                        </button>

                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentPagination from './ComponentPagination.vue';
import Multiselect from 'vue-multiselect';

export default {
    name: "ComponentActions",
    components: {ComponentPagination, Multiselect},
    props: {
        primaryActions: {
            type: [Array],
            required: false
        },
        secondaryActions: {
            type: Array,
            required: false,
            default: null
        },
        bulk: {
            type: Boolean,
            required: false,
            default: false
        },
        bulkActions: {
            type: Array,
            required: false,
            default: null
        },
        actionFilters: {
            type: [Array],
            required: false
        },
        page: {
            type: [String, Number, null],
            required: false
        },
        itemsPerPage: {
            type: [String, Number],
            required: false
        },
        total: {
            type: [Number],
            required: true
        },
        generalSearchFields: {
            type: [Array],
            required: false
        },
        extendedSearch: {
            type: [Array],
            required: false
        }
    },
    data() {
        return {
            filterOpen: false,
            searchExpanded: false,
            searchTerm: "",
            selectedFilters: [],
            filtersApplied: false,
            generalSearchApplied: false,
            detailSearchApplied: false,
            extendedSearchValues: [],
            activeFiltersCount: 0,
            itemsPerPageOptions: [10, 25, 50, 100]
        }
    },
    computed: {
        availableFilters() {
            let availableFilters = this.actionFilters.map(filter => {
                if (!this.selectedFilters.find(({key}) => key === filter.key)) {
                    return filter;
                }
            })

            return availableFilters.filter(Boolean);
        },
        formattedFilters() {
            let formattedFilters = {}
            this.selectedFilters.forEach(filter => {
                if (filter.operator !== 'in' && filter.operator !== 'notIn') {
                    formattedFilters[filter.key] = {[filter.operator]: filter.value}
                } else {
                    let filterArray = filter.value.map(item => {
                        return item.value
                    })
                    formattedFilters[filter.key] = {[filter.operator]: filterArray}
                }
            })

            return formattedFilters;
        },
        formattedGeneralSearch() {
            let formattedFilters = {}
            this.generalSearchFields.forEach(key => {
                formattedFilters[key] = {['like']: '%' + this.searchTerm + "%"};
            })
            return formattedFilters
        },
        formattedDetailSearch() {
            let formattedFilters = {}
            this.extendedSearchValues.forEach(value => {
                let key = Object.keys(value)[0];

                if (value[key]) {
                    formattedFilters[key] = {['like']: '%' + value[key] + "%"};
                }
            })
            return formattedFilters
        }
    },
    beforeMount() {
        window.addEventListener('mouseup', this.closeFilters);
        if (this.extendedSearch) {
            this.extendedSearchValues = this.extendedSearch.map(input => {
                return {[input.technicalName]: ''};
            })
        }
    },
    methods: {
        paginate(page) {
            this.$emit('paginate', page)
        },
        closeFilters(event) {
            let filterContainer = document.querySelector('#filter-container');

            if (!this.filterOpen || event.target === filterContainer) {
                return;
            }

            if (!filterContainer.contains(event.target)) {
                this.filterOpen = false;
            }
        },
        addFilter() {
            if (this.availableFilters.length > 0) {
                this.selectedFilters.push({
                    key: this.availableFilters[0].key,
                    operator: 'eq',
                    value: this.availableFilters[0].values[0].value,
                    title: this.availableFilters[0].title
                })
            }
        },
        onChangeFilter(filterKey, filterIndex) {
            let filter = this.actionFilters.find(({key}) => key === filterKey);
            this.selectedFilters.splice(filterIndex, 1, {
                key: filterKey,
                operator: 'eq',
                value: filter.values[0].value,
                title: filter.title
            })
            this.$forceUpdate();
        },
        getFilterOptions(selectedKey) {
            let filter = this.actionFilters.find(({key}) => key === selectedKey);
            return filter.values
        },
        setFilterOptions(i) {
            if (this.selectedFilters[i].operator === 'in' || this.selectedFilters[i].operator === 'notIn') {
                this.selectedFilters[i].value = []
            } else if (typeof (this.selectedFilters[i].value) == 'object') {
                this.selectedFilters[i].value = this.getFilterOptions(this.selectedFilters[i].key)[0].value
            }
        },
        applyFilters() {
            let formattedFilters = this.formattedFilters;
            this.activeFiltersCount = this.selectedFilters.length;
            this.$emit('applyFilters', formattedFilters);
            this.filterOpen = false;
        },
        detailSearch() {
            let formattedFilters = {};

            if (this.filtersApplied) {
                formattedFilters = this.formattedFilters;
            }

            if (this.generalSearchApplied) {
                this.searchTerm = ""
                this.generalSearchApplied = false;
            }

            let filters = Object.assign({}, formattedFilters, this.formattedDetailSearch)
            filters._condition = "and";

            this.$emit('applyFilters', filters);
        },
        generalSearch() {
            let formattedFilters = {}

            if (this.filtersApplied) {
                formattedFilters = this.formattedFilters;
            }

            if (this.detailSearchApplied) {
                this.extendedSearchValues = [];
                this.detailSearchApplied = false;
            }

            let filters = Object.assign({}, formattedFilters, this.formattedGeneralSearch)
            filters._condition = "or";

            this.$emit('applyFilters', filters);
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#extendedSearch {
    background-color: #f3f3f3;
}
</style>

<style>
.multiselect {
    min-height: 0;
}

.multiselect__tags {
    min-height: 26px;
    padding-top: 0.25rem;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.7rem;
    color: #212529;
    background-color: #FFFFFF;
    display: flex;
    min-width: 217px;
    text-align: left;
}

.multiselect__placeholder {
    color: #212529;
    padding-top: 0;
    margin-bottom: 0;
}

.multiselect__select {
    display: none;
}

.multiselect__option {
    min-height: 0;
    font-size: 0.7rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;

}

.multiselect__option--highlight:after {
    display: none;
}

.multiselect__option--selected:after {
    content: "";
    color: rgba(0, 0, 0, 0.075);
}

.multiselect__input {
    width: 0 !important;
    height: 0 !important;
}

.multiselect__tag,
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background-color: #708238;
}

.multiselect__tag-icon:after {
    color: #fff;
}

.multiselect__option--highlight {
    background: #708238;
}

.multiselect__option--selected:hover {
    background-color: #DC3545
}

.multiselect__tag {
    margin: 0;
    margin-right: 3px;
}

</style>



