<template>
    <nav aria-label="Pagination" class="p-0 m-0">
        <ul class="d-flex py-0 my-0" style="list-style-type: none;">
            <li>
                <small>{{ $tc('page', 1) }}:</small>
            </li>
            <li>
                <button :class="{ 'text-primary': mutablePage !== 1 }"
                        :disabled="mutablePage === 1" class="btn py-0 btn-sm px-1"
                        @click="onChangePage(1)">
                    {{ $t('first') }}
                </button>
            </li>
            <li>
                <button :class="{ 'text-primary': mutablePage !== 1 }"
                        :disabled="mutablePage <= 1"
                        class="btn py-0 btn-sm px-1"
                        @click="onChangePage(--mutablePage)">
                    <font-awesome-icon icon="angle-left"/>
                </button>
            </li>
            <li v-if="mutablePage > 2">
                <button class="btn py-0 btn-sm text-primary px-1"
                        @click="onChangePage(mutablePage - 2)">
                    {{ mutablePage - 2 }}
                </button>
            </li>
            <li v-if="mutablePage > 1">
                <button class="btn py-0 btn-sm text-primary px-1"
                        @click="onChangePage(mutablePage - 1)">
                    {{ mutablePage - 1 }}
                </button>
            </li>
            <li class="page-item active">
                <button class="btn py-0 btn-sm px-1" disabled>
                    <u>
                        {{ mutablePage }}
                    </u>
                </button>
            </li>
            <li v-if="mutablePage < lastPage">
                <button class="btn py-0 btn-sm text-primary px-1"
                        @click="onChangePage(mutablePage + 1)">
                    {{ mutablePage + 1 }}
                </button>
            </li>
            <li v-if="mutablePage < (lastPage - 1)">
                <button class="btn py-0 btn-sm text-primary px-1"
                        @click="onChangePage(mutablePage + 2)">
                    {{ mutablePage + 2 }}
                </button>
            </li>
            <li>
                <button :class="{ 'text-primary': mutablePage !== lastPage }"
                        :disabled="mutablePage === lastPage"
                        class="btn py-0 btn-sm px-1"
                        @click="onChangePage(mutablePage + 1)">
                    <font-awesome-icon icon="angle-right"/>
                </button>
            </li>
            <li>
                <button :class="{ 'text-primary': mutablePage !== lastPage }"
                        :disabled="mutablePage === lastPage"
                        class="btn py-0 btn-sm px-1"
                        @click="onChangePage(lastPage)">
                    {{ $t('last') }}
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "ComponentPagination",
    computed: {
        lastPage() {
            return Math.ceil(this.$props.total / this.$props.limit);
        }
    },
    props: {
        page: {
            type: [String, Number],
            required: true
        },
        limit: {
            type: [String, Number],
            required: false
        },
        total: {
            type: [Number],
            required: false
        }
    },
    data() {
        return {
            mutablePage: parseInt(this.page)
        }
    },
    methods: {
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.mutablePage = parseInt(page);
            this.$emit('paginate', this.mutablePage);
        }
    },
    watch: {
        page(page) {
            this.mutablePage = parseInt(page);
        }
    }
}
</script>

<style lang="scss" scoped>
nav {
    display: inline-block;
}

.btn:focus {
    box-shadow: none;
}
</style>
